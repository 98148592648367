<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <qzf-search-period v-model:period="listQuery.period" @success="getList" style="width:100px"></qzf-search-period>
        <el-input placeholder="请输入企业名称/编号" v-model="listQuery.name" style="width: 160px;margin-left:5px" size="small" @keyup.enter="getList" clearable/>
        <el-button type="primary" size="small" @click="getList" icon="Search" style="margin-right:5px">搜索</el-button>
        <search @success="getList" @cancel="cancel" name="高级筛选">
          <el-form
            style="width: 610px;"
            label-width="110px"
            :inline="true"
          >
            <el-form-item label="税局："> 
              <selectcity v-model:citys="listQuery.districtCode" style="width:150px"></selectcity>
            </el-form-item>
            <el-form-item label="授权状态 ：" :label-width="formLabelWidth">   
              <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:150px"></selectAccreditStatus>
            </el-form-item>
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:150px;"></selectuser>
            </el-form-item>
            <el-form-item label="纳税人类型：">          
              <selecttaxtype v-model:type="listQuery.type" style="width:150px;"></selecttaxtype>
            </el-form-item> 
            <el-form-item label="是否申报往期：">
              <el-select v-model="listQuery.shebaoPre" placeholder="请选择是否申报往期" style="width:150px" size="small">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司类型："> 
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="width:150px">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="采集状态：">
              <el-checkbox-group size="small" v-model="listQuery.allStatusCj">
                <el-checkbox-button
                  v-for="item in statusOptionsCj"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="申报状态：">
              <selectBussinessStatus v-model:allStatus="listQuery.allStatusSb"></selectBussinessStatus>
            </el-form-item>
            <el-form-item label="扣款状态：">
              <el-checkbox-group size="small" v-model="listQuery.allStatusKk">
                <el-checkbox-button
                  v-for="item in statusOptionsKk"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="检查状态：">
              <el-checkbox-group size="small" v-model="listQuery.allStatusJc">
                <el-checkbox-button
                  v-for="item in statusOptionsJc"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}</el-checkbox-button
                >
              </el-checkbox-group>
            </el-form-item> 
          </el-form>
        </search>
        <el-switch @change="getList" v-model="listQuery.companyShebao" active-text="只显示有社保公司"  active-value="1"  inactive-value="2" style="margin-left:5px" size="small"></el-switch>
      </div>
      <div>
        <el-button size="small" type="primary" @click="batchCollect" icon="Aim" :disabled="!$buttonStatus('sbsb_plcj') || !buttonStatusSb" :loading="cjLoading">批量采集</el-button>
        <el-button size="small" type="primary" @click="batchShenbao" icon="Operation" :disabled="!$buttonStatus('sbsb_plsb') || !buttonStatusSb" :loading="sbLoading" style="margin-left: 5px">批量申报</el-button>
        <el-button size="small" type="primary" @click="batchKk" icon="Postcard" :disabled="!$buttonStatus('sbsb_plkk') || !buttonStatusSb" :loading="kkLoading" style="margin-left: 5px">批量扣款</el-button>
        <el-button size="small" type="primary" @click="batchCheck" icon="SetUp" :disabled="!$buttonStatus('sbsb_pljc') || !buttonStatusSb" :loading="jcLoading" style="margin-left: 5px">批量检查</el-button>
        <el-button size="small" type="primary" @click="batchSetting" icon="Setting" :disabled="!$buttonStatus('sbsb_sfysb') " style="margin-left: 5px">社保设置</el-button>
        <el-button size="small" type="primary" icon="Folder" plain @click="daochu">导出</el-button>
      </div>
    </div>
    <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" border @select="handleSelectionChange" v-loading="loading"  @sort-change="sortChange" ref="tableScroll" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column label="编号" align="center" prop="sortCom" width="80" fixed="left" sortable="custom">
        <template #default="scope">
          <TableSortCell :row="scope.row" idKey="comId" />
        </template>
      </el-table-column>
      <el-table-column prop="name" fixed="left" label="公司名称" min-width="280">
        <template #default="scope">
          <TagNameCopy :row="scope.row" idKey="comId">
            </TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="税局" width="65" align="center">
        <template #default="scope">
          <span>{{ $cityFilter(scope.row.district) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否申报往期" width="100">
        <template #default="scope">
          <span v-if="scope.row.shebaoPre == 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="是否有社保" width="90">
        <template #default="scope">
          <span v-if="scope.row.companyShebao == 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="采集状态" min-width="230">
        <template #default="scope">
          <div class="div_p">
            <div style="float:left" v-if="scope.row.companyShebao == 1">
              <span v-if="scope.row.imageCj" class="demo-image__preview" >
                <i class="iconfont icon-picture" style="color:red;font-size:16px;" @click="openCj(scope.row, scope.$index)" v-if="scope.row.bussinessStatusCj == 3"></i>
                <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;" @click="openCj(scope.row, scope.$index)" v-else></i>
              </span>
              <div class="item_icon">
                <p>
                  <i v-if="!scope.row.imageCj" :class="$batchSendIconCj(scope.row.taskStatusCj, scope.row.bussinessStatusCj)"></i>
                  <el-tooltip :disabled="!scope.row.bussinessLogCj && !scope.row.errLogCj" class="scope.row" effect="dark"  placement="top-start">
                    <template #content>
                      <div v-html="(scope.row.bussinessLogCj?scope.row.bussinessLogCj:'') + (scope.row.errLogCj?scope.row.errLogCj:'')"></div>
                    </template>
                    <div style="display: inline-block;">
                      <span :class="$batchSendWordSb(scope.row.taskStatusCj, scope.row.bussinessStatusCj)">{{$batchSendCj(scope.row.taskStatusCj, scope.row.bussinessStatusCj)}}</span>
                      <i class="iconfont icon-wenhao" v-if="scope.row.bussinessLogCj || scope.row.errLogCj" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                    </div>
                  </el-tooltip>
                </p>
              </div>
            </div>
            <div style="float:left" v-else>
              <div class="item_icon">
                <p>
                  <i class="iconfont icon-info"></i>
                   <span>无需采集</span>
                </p>
              </div>
            </div>
            <el-button style="float:right" @click="checkTableDialog(scope.row,item)" size="small" type="text" :disabled="scope.row.taskStatusCj != '3' || scope.row.bussinessStatusCj != '2'">明细</el-button>
            <span style="margin-left:10px;float:right">金额:{{(scope.row.payableAmount).toFixed(2)}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="申报状态" min-width="140">
        <template #default="scope">
          <div class="div_p">
            <div style="float:left" v-if="scope.row.companyShebao == 1">
              <span v-if="scope.row.imageSb" class="demo-image__preview" >
                <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;" @click="openSb(scope.row, scope.$index)"></i>
              </span>
              <div class="item_icon">
                <p>
                  <i v-if="!scope.row.imageSb" :class="$batchSendIconSb(scope.row.taskStatusSb, scope.row.bussinessStatusSb)"></i>
                  <el-tooltip :disabled="!scope.row.bussinessLogSb && !scope.row.errLogSb" class="scope.row" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="(scope.row.bussinessLogSb?scope.row.bussinessLogSb:'') + (scope.row.errLogSb?scope.row.errLogSb:'')"></div>
                    </template>
                    <div style="display: inline-block;">
                      <span :class="$batchSendWordSb(scope.row.taskStatusSb, scope.row.bussinessStatusSb)">{{$batchSendSb(scope.row.taskStatusSb, scope.row.bussinessStatusSb)}}</span>
                      <i class="iconfont icon-wenhao" v-if="(scope.row.bussinessStatusSb == 3 && scope.row.bussinessLogSb) || scope.row.errLogSb" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                    </div>
                  </el-tooltip>
                </p>
              </div>
            </div>
            <div style="float:left" v-else>
              <div class="item_icon">
                <p>
                  <i class="iconfont icon-info"></i>
                   <span>无需申报</span>
                </p>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="扣款状态" min-width="200">
        <template #default="scope">
          <div class="div_p">
            <div style="float:left" v-if="scope.row.companyShebao == 1">
              <span v-if="scope.row.imageKk" class="demo-image__preview" >
                <i class="iconfont icon-picture" style="color:red;font-size:16px;" @click="openKk(scope.row, scope.$index)" v-if="scope.row.bussinessStatusKk == 3"></i>
                <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;" @click="openKk(scope.row, scope.$index)" v-else></i>
              </span>
              <div class="item_icon">
                <p>
                  <i v-if="!scope.row.imageKk" :class="$batchSendIconSb(scope.row.taskStatusKk, scope.row.bussinessStatusKk)"></i>
                  <el-tooltip :disabled="!scope.row.bussinessLogKk && !scope.row.errLogKk" class="scope.row" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="scope.row.bussinessLogKk?scope.row.bussinessLogKk:'' + scope.row.errLogKk?scope.row.errLogKk:''"></div>
                    </template>
                    <div style="display: inline-block;">
                      <span :class="$batchSendWordKk(scope.row.taskStatusKk, scope.row.bussinessStatusKk)">{{$batchSendKk(scope.row.taskStatusKk, scope.row.bussinessStatusKk)}}</span>
                      <i class="iconfont icon-wenhao" v-if="(scope.row.taskStatusKk == 3 && scope.row.bussinessLogKk) || scope.row.errLogKk" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                    </div>
                  </el-tooltip>
                  <span style="color: #67c23a;margin-left: 5px;">金额：</span>
                  <span v-if="scope.row.kk_amount">{{scope.row.kk_amount}} 
                    <el-tooltip class="item" effect="dark" placement="top" v-if="scope.row.bussinessStatusKk == 2 || scope.row.bussinessStatusKk == 13">
                      <template #content>
                        <div>缴款时间：{{ scope.row.endAtKk }}</div>
                      </template>
                      <i class="iconfont icon-gantanhao gantanhao-yc"></i>
                    </el-tooltip>  </span>
                </p>
              </div>
            </div>
            <div style="float:left" v-else>
              <div class="item_icon">
                <p>
                  <i class="iconfont icon-info"></i>
                   <span>无需扣款</span>
                </p>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="检查状态" min-width="180">
        <template #default="scope">
          <div class="div_p">
            <div style="float:left" v-if="scope.row.companyShebao == 1">
              <span v-if="scope.row.imageJc" class="demo-image__preview" >
                <i class="iconfont icon-picture" style="color:red;font-size:16px;" @click="openJc(scope.row, scope.$index)" v-if="scope.row.bussinessStatusJc == 3"></i>
                <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;" @click="openJc(scope.row, scope.$index)" v-else></i>
              </span>
              <div class="item_icon">
                <p>
                  <i v-if="!scope.row.imageJc" :class="$setUpAllStatusIconJc(scope.row.taskStatusJc, scope.row.bussinessStatusJc)"></i>
                  <el-tooltip :disabled="!scope.row.bussinessLogJc && !scope.row.errLogJc" class="scope.row" effect="dark" placement="top-start">
                    <template #content>
                      <div v-html="(scope.row.bussinessLogJc?scope.row.bussinessLogJc:'') + (scope.row.errLogJc?scope.row.errLogJc:'')"></div>
                    </template>
                    <div style="display: inline-block;">
                      <span :class="$batchSendWordJc(scope.row.taskStatusJc, scope.row.bussinessStatusJc)">{{$batchSendJc(scope.row.taskStatusJc, scope.row.bussinessStatusJc)}}</span>
                      <i class="iconfont icon-wenhao" v-if="(scope.row.bussinessStatusJc == 3 && scope.row.bussinessLogJc)||scope.row.errLogJc" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                    </div>
                  </el-tooltip>
                </p>
              </div>
            </div>
            <div style="float:left" v-else>
              <div class="item_icon">
                <p>
                  <i class="iconfont icon-info"></i>
                   <span>无需检查</span>
                </p>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="taxPay"/>
    </div>
  </div>

  <el-dialog
    v-model="dialogVisible"
    title="社保设置"
    width="400px"
    :before-close="handleClose"
  >
    <div style="display: flex;">
      <div style="font-size:13px;width: 100px;text-align: right;">是否有社保：</div>
      <el-select v-model="settingType" placeholder="请选择是否有社保" style="width:190px" size="small">
        <el-option label="是" :value="1"></el-option>
        <el-option label="否" :value="0"></el-option>
      </el-select>
    </div>
    <div style="display: flex;margin-top: 20px;height: 20px;align-items: center;">
      <div style="font-size:13px;width: 100px;text-align: right;">是否申报往期：</div>
      <el-select v-model="shenBaoType" placeholder="请选择是否申报往期" style="width:190px" size="small">
        <el-option label="是" :value="1"></el-option>
        <el-option label="否" :value="0"></el-option>
      </el-select>
        <!-- <i class="iconfont icon-wenhao" style="cursor: pointer;font-size:16px;right:-20px;font-weight: bold;"></i> -->

      <!-- <el-tooltip content="1、申报往期，则系统采集往期未申报、申报往期未申报、扣款往期未缴款" placement="top">
        <el-icon style="cursor: pointer;font-size:16px;font-weight: bold;"><QuestionFilled /></el-icon>
      </el-tooltip> -->
      <el-tooltip placement="top">
        <template #content>1、申报往期，则系统采集往期未申报、申报往期未申报、扣款往期未缴款<br />2、申报往期社保扣款包含往期滞纳金扣款 <br />3、采集申报往期社保仅支持本年</template>
        <el-icon style="cursor: pointer;font-size:16px;font-weight: bold;color:red"><QuestionFilled /></el-icon>
      </el-tooltip>
    </div>
    <!-- <div><span> <i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c;margin-right:4px"></i>1、申报往期，则系统采集往期未申报、申报往期未申报、扣款往期未缴款<br />2、申报往期社保扣款包含往期滞纳金扣款 <br />3、采集申报往期社保仅支持半年以内</span></div> -->

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="makeSureSetting" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>


  <checkTbaleDialogs ref="checkTbaleDialogs"></checkTbaleDialogs>
  <settleHint />
  <sbImage ref="sbImage" />
</template>

<script>
import { companyShebaoList ,companyShebaoStatus } from "@/api/company";
import { currentAccountPeriod } from "@/utils";
import { sendTask } from "@/api/collection"
import selecttaxtype from "@/components/Screening/selecttaxtype";
import checkTbaleDialogs from './components/checkTbaleDialog.vue'
import selectBussinessStatus from "./components/select/selectBussinessStatus.vue"
import selectcity from "@/components/Screening/selectcity";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import settleHint from "./components/settleHint.vue";
import sbImage from '@/components/sbImage'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { ACCOUNT_SYSTEM_OPTION } from '@/utils/commonData.js'
import {companyShebaoListExport } from '@/api/export'
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'

export default {
  name: "taxPay",
  components: {
    selecttaxtype,
    checkTbaleDialogs,
    selectBussinessStatus,
    selectcity,
    selectAccreditStatus,
    settleHint,
    sbImage,
    TagNameCopy,
    TableSortCell
  },
  props: {},
  data() {
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      activeName: "税款申报",
      list: [],
      listQuery: {
        page: 1,
        limit: 20,
        period: currentAccountPeriod(),
        companyShebao:'2',
        shebaoPre:'',
        status:2,
        accountSystem:'',
        userId:null,
        companyType:''
      },
      options: ACCOUNT_SYSTEM_OPTION,
      pageStatus: true,
      loading:false,
      selects:[],
      gsMainIds:[],
      statusOptionsCj: [
        {
          value: "task-0",
          label: "未采集",
        },
        {
          value: "task-99",
          label: "发起中",
        },
        {
          value: "task-1",
          label: "排队中",
        },
        {
          value: "task-2",
          label: "采集中",
        },
        {
          value: "business-2",
          label: "采集成功",
        },
        {
          value: "business-20",
          label: "已申报过",
        },
        {
          value: "business-3",
          label: "失败",
        },
      ],
      statusOptionsKk: [
       
        {
          value: "task-0",
          label: "未扣款",
        },
        {
          value: "task-99",
          label: "发起中",
        },
        {
          value: "task-1",
          label: "排队中",
        },
        {
          value: "task-2",
          label: "扣款中",
        },
        {
          value: "business-2",
          label: "扣款成功",
        },
        {
          value: "business-6",
          label: "成功(系统扣款)",
        },
        {
          value: "business-22",
          label: "成功(非系统扣款)",
        },
        {
          value: "business-3",
          label: "扣款失败",
        },
      ],
      statusOptionsJc: [
       
        {
          value: "task-0",
          label: "未检查",
        },
        {
          value: "task-99",
          label: "发起中",
        },
        {
          value: "task-1",
          label: "排队中",
        },
        {
          value: "task-2",
          label: "检查中",
        },
        {
          value: "business-2",
          label: "检查成功",
        },
        {
          value: "business-30",
          label: "有遗漏",
        },
        {
          value: "business-3",
          label: "失败",
        },
      ],
      dialogVisible:false,
      settingType:undefined,
      shenBaoType:undefined,
      buttonStatusSb:true,
      cjLoading:false,
      sbLoading:false,
      kkLoading:false,
      jcLoading:false,
      totalPage:0,
      pageSize:40,
      tableData:[],
      currentPage:1,
      allChecked:false,
      supportAddress:["32",'41','44','46','42','51','33','37','11','34',"13","12",'22',"53","23","65","50","63","35","36"],
      supportAddress1:["32",'41','44','46','42','51','33','37','11','34',"13","12",'22',"23","65","50","63","35","36"]
    };
  },
  created() {
    this.listQuery.limit = localStorage.getItem('taxPay') ? localStorage.getItem('taxPay')*1 : 20
    this.getList();
    this.contentStyleObj = this.$getHeight(190);
    this.initBus();
  },
  methods: {
     //批量导出
     daochu(){
      let ids = []
      this.selects.map(v=>{
        ids.push(v.comId)
      })
      this.listQuery.ids = ids
      companyShebaoListExport(this.listQuery).then(res=>{
        if(res.data.msg == 'success'){
          window.open(res.data.data.url)
        }
      })
    },
    // 重置
    cancel(){
      let limitTemp =this.listQuery.limit
      this.listQuery={ 
        page: 1,
        limit: limitTemp,
        period: currentAccountPeriod(),
        companyShebao:'2',
        status:2,
        districtCode:null,
        keepStatus:null,
        allStatusSb:[],
        allStatusKk:[],
        allStatusJc:[],
        accountSystem:'',
        userId:null,
        shebaoPre:'',
        companyType:''
       }
      this.getList();
     },
    initBus() {
      this.$bus.on("refreshTask", () => {
        if (!this.pageStatus) {
          return;
        }
        if (this.gsMainIds.length != 0) {
          return;
        }
        if (this.list.length == 0) {
          return;
        }
        //刷新业务
        let status = false;
        this.list.forEach((res) => {
          if ([1,2,99].includes(res.taskStatusCj) || [1,2,99].includes(res.taskStatusSb) || [1,2,99].includes(res.taskStatusKk) || [1,2,99].includes(res.taskStatusJc)) {
            status = true;
          }
        });
        if (status == true) {
          companyShebaoList(this.listQuery).then((res) => {
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.total = res.data.data.total
            this.setScroll()
          });
        }
      });
    },
    getList() {
      if(this.listQuery.period != currentAccountPeriod()){
        this.buttonStatusSb = false
      }else{
        this.buttonStatusSb = true
      }
      this.loading = true
      companyShebaoList(this.listQuery).then((res) => {
        this.loading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
        this.setScroll()
      });
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.gsMainIds = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    getIds(){
      this.gsMainIds = []
      this.selects.map(info=>{
        this.gsMainIds.push(info.comId)
      })
    },
    //多选
    handleSelectionChange(val){
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.selects = []
        this.gsMainIds = []
        this.allChecked = false
      }
    },
    //统一过滤授权状态
    filterLoginStatus(){
      let str = ''
      this.selects.map(v=>{
        if(v.district != 'zhejiang' && v.district != 'guangdong' && v.district != 'tianjin' && v.district != 'hubei' && v.loginMethod == '新版登录' && v.yzmType == 1 && v.keepStatus != 1){
          str = v.name + '未授权登录，请先授权再发起任务！'
          return
        }
      })
      return str
    },
    //统一过滤是否有社保状态
    filterShebaoStatus(){
      let str = ''
      this.selects.map(v=>{
        if(v.companyShebao != 1){
          str = v.name + '无需操作，请重新选择'
          return
        }
      })
      return str
    },
    //批量采集
    batchCollect(){
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let loginStr = ''
      loginStr = this.filterLoginStatus()
      if(loginStr){
        this.$qzfMessage(loginStr,1)
        return
      }
      //过滤是否有社保状态
      let shebaoStr = ''
      shebaoStr = this.filterShebaoStatus()
      if(shebaoStr){
        this.$qzfMessage(shebaoStr,1)
        return
      }
      let status = false
      // 江苏  河南  广东 海南 湖北 四川  浙江 青岛 北京 安徽 上海 辽宁 天津 吉林 山东 黑龙江 新疆 青海 福建 江西
      this.selects.map(v=>{
        let test = v.districtCode.substring(0, 2)
        if (!this.supportAddress.includes(test) && v.districtCode.substring(0, 4) != "3100" && v.districtCode.substring(0, 4) != "2100"){
          status = true
          this.$qzfMessage(v.name+'该地区不支持',1)
          return
        }
      })
      if (status){
        return
      }
      this.cjLoading = true
      let param = {
        taskName: 'tax-cj-shebao',
        comIds: this.gsMainIds,
        period: this.listQuery.period
      }
      sendTask(param).then(res => {
        this.cjLoading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //批量申报
    batchShenbao(){
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let loginStr = ''
      loginStr = this.filterLoginStatus()
      if(loginStr){
        this.$qzfMessage(loginStr,1)
        return
      }
      //过滤是否有社保状态
      let shebaoStr = ''
      shebaoStr = this.filterShebaoStatus()
      if(shebaoStr){
        this.$qzfMessage(shebaoStr,1)
        return
      }
      //过滤采集状态
      let cjStatus = false
      this.selects.map(v=>{
        if(v.taskStatusCj != 3){
          cjStatus = true
          return
        }
      })
      if(cjStatus){
        this.$qzfMessage('请先进行采集',1)
        return
      }
      let status = false
      this.selects.map(v=>{
        let test = v.districtCode.substring(0, 2)
        if (!this.supportAddress.includes(test) && v.districtCode.substring(0, 4) != "3100" && v.districtCode.substring(0, 4) != "2100"){
          status = true
          this.$qzfMessage(v.name+'该地区不支持',1)
          return
        }
      })
      if (status){
        return
      }
      this.sbLoading = true
      let param = {
        taskName: 'tax-sb-shebao',
        comIds: this.gsMainIds,
        period: this.listQuery.period
      }
      sendTask(param).then(res => {
        this.sbLoading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)

        }
      })
    },
    //批量扣款
    batchKk(){
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let loginStr = ''
      loginStr = this.filterLoginStatus()
      if(loginStr){
        this.$qzfMessage(loginStr,1)
        return
      }
      //过滤是否有社保状态
      let shebaoStr = ''
      shebaoStr = this.filterShebaoStatus()
      if(shebaoStr){
        this.$qzfMessage(shebaoStr,1)
        return
      }
      //过滤申报状态
      let sbStatus = false
      this.selects.map(v=>{
        if(v.taskStatusSb != 3){
          sbStatus = true
          return
        }
      })
      if(sbStatus){
        this.$qzfMessage('请先进行申报',1)
        return
      }
      let status = false
      this.selects.map(v=>{
        let test = v.districtCode.substring(0, 2)
        if (!this.supportAddress.includes(test) && v.districtCode.substring(0, 4) != "3100" && v.districtCode.substring(0, 4) != "2100"){
          status = true
          this.$qzfMessage(v.name+'该地区不支持',1)
          return
        }
      })
      if (status){
        return
      }
      this.kkLoading = true
      let param = {
        taskName: 'tax-kk-shebao',
        comIds: this.gsMainIds,
        period: this.listQuery.period
      }
      sendTask(param).then(res => {
        this.kkLoading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    //批量检查
    batchCheck(){
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let loginStr = ''
      loginStr = this.filterLoginStatus()
      if(loginStr){
        this.$qzfMessage(loginStr,1)
        return
      }
      //过滤是否有社保状态
      let shebaoStr = ''
      shebaoStr = this.filterShebaoStatus()
      if(shebaoStr){
        this.$qzfMessage(shebaoStr,1)
        return
      }
      let status = false
      this.selects.map(v=>{
        let test = v.districtCode.substring(0, 2)
        if (!this.supportAddress1.includes(test) && v.districtCode.substring(0, 4) != "3100" && v.districtCode.substring(0, 4) != "2100" ){
          status = true
          this.$qzfMessage(v.name+'该地区不支持',1)
          return
        }
      })
      if (status){
        return
      }
      this.$confirm('确定要批量检查吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.jcLoading = true
        let param = {
          taskName: 'tax-jc-shebao',
          comIds: this.gsMainIds,
          period: this.listQuery.period
        }
        sendTask(param).then(res => {
          this.jcLoading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)
          }
        })
      })
    },
    // 批量设置是否有社保
    batchSetting(){
      this.settingType = undefined
      this.shenBaoType = undefined
      if(this.gsMainIds.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.dialogVisible = true
    },
    makeSureSetting(){
      this.selects.map(v => {
        v.companyShebao = this.settingType
        v.shebaoPre = this.shenBaoType
      })
      companyShebaoStatus(this.selects).then(res => {
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.dialogVisible = false
          this.getList()
        }
      })
    },
    handleClick(tab) {
      if (tab.props.name == "税款申报") {
        this.listQuery.accountingType = 2;
        this.getList();
      }
      if (tab.props.name == "零申报") {
        this.listQuery.accountingType = 1;
        this.getList();
      }
    },
    // 查看采集的社保
    checkTableDialog(row){
      let param = {
        comId:row.comId,
        period:this.listQuery.period,
        tableName:'tax-cj-shebao',
        comName:row.name,
      }
      this.$refs.checkTbaleDialogs.openDialog(param)
    },
    // 表格排序
    sortChange(data) {
      if(data.order == "descending"){
        this.listQuery.desc = 1
      }else{
        this.listQuery.desc = 0
      }
      this.getList()
    },
    openCj(row,index){
      let imageAll = []
      this.list.map(res=>{
        let arr = []
        if(res.imageCj.match(',')){
          let imgUrls = res.imageCj.split(',')
          imgUrls.map(item=>{
            if(item){
              arr.push({
                  img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                  tableName:""
                })
            }
          })
        }else{
          if(res.imageCj){
            arr.push({
              img:'https://file.listensoft.net' + res.imageCj + "?" + Math.random(10),
              tableName:""
            })
          }
        }
        let url = {
          name:res.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index,0)
    },
    openSb(row,index){
      let imageAll = []
      this.list.map(res=>{
        let arr = []
        if(res.imageSb.match(',')){
          let imgUrls = res.imageSb.split(',')
          imgUrls.map(item=>{
            if(item){
              arr.push({
                  img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                  tableName:""
                })
            }
          })
        }else{
          if(res.imageSb){
            arr.push({
              img:'https://file.listensoft.net' + res.imageSb + "?" + Math.random(10),
              tableName:""
            })
          }
        }
        let url = {
          name:res.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index,0)
    },
    openKk(row,index){
      let imageAll = []
      this.list.map(res=>{
        let arr = []
        if(res.imageKk.match(',')){
          let imgUrls = res.imageKk.split(',')
          imgUrls.map(item=>{
            if(item){
              arr.push({
                  img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                  tableName:""
                })
            }
          })
        }else{
          if(res.imageKk){
            arr.push({
              img:'https://file.listensoft.net' + res.imageKk + "?" + Math.random(10),
              tableName:""
            })
          }
        }
        let url = {
          name:res.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index,0)
    },
    openJc(row,index){
      let imageAll = []
      this.list.map(res=>{
        let arr = []
        if(res.imageJc.match(',')){
          let imgUrls = res.imageJc.split(',')
          imgUrls.map(item=>{
            if(item){
              arr.push({
                  img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                  tableName:""
                })
            }
          })
        }else{
          if(res.imageJc){
            arr.push({
              img:'https://file.listensoft.net' + res.imageJc + "?" + Math.random(10),
              tableName:""
            })
          }
        }
        let url = {
          name:res.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index,0)
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 4px;
  }
  p{
    display: inline-block;
    font-size: 13px;
    //line-height: 28px;
    color: #333;
  }
}
</style>
