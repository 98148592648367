<template>
  <div class="app-container">
    <div style="display: flex;justify-content: space-between;">
      <div>
        <el-input placeholder="请输入客户名称或合同（订单号）" v-model="listQuery.nameOrNumber" style="width: 190px;margin-left:5px" size="small" @keyup.enter="getList" clearable/>
        <el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel" name="高级筛选">
          <el-form
            style="width: 400px;"
            label-width="110px"
          >
            <el-form-item label="创建时间："> 
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.createdTimeSt"
                type="date"
                placeholder="起始日期"
                clearable
                value-format="YYYY-MM-DD"
              /> &nbsp;-&nbsp;
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.createdTimeEd"
                type="date"
                placeholder="终止日期"
                clearable
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item label="业务类型："> 
              <cascader-common
                v-model:modelValue="listQuery.businessTypeId"
                placeholder="请选择业务类型"
                :options="contractTypeOptions"
                :casProps="casProps"
                style="width: 234px"
              />
            </el-form-item>
            <el-form-item label="任务负责人："> 
              <selectuser code="addCustomer" v-model:userId="listQuery.userId" :multiples="false" style="width: 234px;margin: 0;" ></selectuser>
            </el-form-item>
            <el-form-item label="步骤办理人："> 
              <selectuser code="addCustomer" v-model:userId="listQuery.handleId" :multiples="false" style="width: 234px;margin: 0;" ></selectuser>
            </el-form-item>
            <el-form-item label="步骤名称："> 
              <el-input placeholder="" v-model="listQuery.infoFilter" style="width: 234px;margin:0" size="small" clearable/>
            </el-form-item>
            <el-form-item label="办理状态"> 
              <el-select
                v-model="listQuery.followStage"
                placeholder="请选择"
                style="width: 234px; margin: 0"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in handleStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button
          type="primary"
          @click="addTask"
          size="small"
          icon="Plus"
          >新增</el-button
        >
        <el-button
          type="primary"
          @click="endTask('finish')"
          size="small"
          >完成</el-button
        >
        <el-button
          type="primary"
          @click="endTask('end')"
          size="small"
          >终止</el-button
        >
        <el-button
          type="primary"
          @click="daochu"
          size="small"
          >导出</el-button
        >
      </div>
    </div>
    <!-- 列表 -->
    <div>
      <el-table
        :data="taskList"
        style="width: 100%; margin-top: 10px"
        border
        :height="contentStyleObj"
        @selection-change="handleSelectClue"
        v-loading="listLoading"
        stripe
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" fixed />
        <el-table-column
          prop="contacter"
          label="客户编码"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="link"
          label="客户名称"
          min-width="120"
          align="left"
        />
        <el-table-column
          prop="contactsNa"
          label="业务类型"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="cont"
          label="负责人"
          min-width="80"
          align="left"
        />
        <el-table-column
          prop="followUserName"
          label="状态"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="cont"
          label="完成时间"
          min-width="80"
          align="left"
        />
        <el-table-column
          prop="followUserName"
          label="创建人"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="followUserName"
          label="创建时间"
          min-width="100"
          align="left"
        />
        <el-table-column
          label="操作"
          align="center"
          width="150"
          fixed="right"
        >
          <template #default="scope">
            <!-- <el-button
              v-if="activeName == 'no'"
              type="text"
              size="small"
              @click="toProtect(scope.row,'no')"
              style="margin: 0;padding: 5px 2px"
              icon="Opportunity"
            >保护</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 新增 -->
    <taskform ref="taskform" :title="titleDrawer" @success="getList"></taskform>
  </div>
</template>

<script>
import selectBusiness from "../../crm/components/selectBusiness.vue";
import { contractTypeList,taskList } from "@/api/newCrm.js";
import { HANDL_STATUE } from "@/utils/commonData";
import taskform from "./components/taskForm.vue";

export default {
  name: '',
  components: {
    selectBusiness,
    taskform
  },
  props: {},
  data () {
    return {
      listQuery: {
        page: 1,
        size: 10,
        nameOrNumber: '',
        createdTimeSt: '',
        createdTimeEd: '',
        businessTypeId: null,
        userId: null,
        handleId: null,
      },
      taskList: [],
      total: 0,
      options: [],
      categoryList: [],
      form: {
        server: []
      },
      addDrawer: false,
      taskForm: {},
      casProps: {
        expandTrigger: "hover",
        label: "name",
        value: "id",
      },
      contractTypeOptions: [],//业务类型
      ids: [],
      select: [],
      handleStatusList: HANDL_STATUE,//办理状态
      titleDrawer:'',//抽屉title
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getBussList()
    this.contentStyleObj = this.$getHeight(318);
    this.getList()
  },
  mounted () {},
  methods: {
    getBussList(){
      contractTypeList({ contractType: '2' }).then((res) => {
        this.contractTypeOptions = res.data.data.list;
      });
    },
    getList(){
      taskList(this.listQuery).then((res) => {
        // this.contractTypeOptions = res.data.data.list;
        console.log(res);
      });
    },
    addTask(){
      this.titleDrawer = '新增任务'
      this.$refs.taskform.init()
    },
    endTask(type){
      if(this.select.find(item => item.mm == 1)){
        this.$qzfMessage("所选任务中包含已完成或终止任务!请重新选择")
        return
      }
      if(type == 'end'){

      }else{

      }
    },
    // 列表勾选
    handleSelectClue(val) {
      this.ids = []
      this.select = []
      this.select = val;
      this.select.map((v,i)=>{
        this.ids.push(v.id)
      })
    },
     // 业务类型相关操作
     getOptions() {
      // 初始化 options
      this.options = [];

      // 确保 categoryList 和 form.server 都存在
      if (!this.categoryList || !this.form.server) return;

      // 创建一个映射表用于快速查找
      const serverMap = new Map();
      this.form.server.forEach((item) => {
        if (!item.id) {
          // 设置 disposable 属性
          item.disposable = item.categoryNameId === 5 ? "0" : "1";
        }
        // 使用 Map 存储 id 对应的 item
        serverMap.set(item.categoryNameId, item);
      });

      // 遍历 categoryList 并添加符合条件的子项
      this.categoryList.forEach((v) => {
        v.children.forEach((k) => {
          if (serverMap.has(k.id)) {
            this.options.push(k);
          }
        });
      });

      // 去重
      this.options = [...new Set(this.options.map((option) => option.id))].map(
        (id) => {
          return this.options.find((option) => option.id === id);
        }
      );
    },

    getCategory() {
      categoryListNew({}).then((res) => {
        if (res.data.msg == "success") {
          this.categoryList = res.data.data.list ? res.data.data.list : [];
          this.getOptions();
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
</style>
